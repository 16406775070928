exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-digital-marketing-jsx": () => import("./../../../src/pages/digital-marketing.jsx" /* webpackChunkName: "component---src-pages-digital-marketing-jsx" */),
  "component---src-pages-es-contacto-jsx": () => import("./../../../src/pages/es/contacto.jsx" /* webpackChunkName: "component---src-pages-es-contacto-jsx" */),
  "component---src-pages-es-desarrollo-web-jsx": () => import("./../../../src/pages/es/desarrollo-web.jsx" /* webpackChunkName: "component---src-pages-es-desarrollo-web-jsx" */),
  "component---src-pages-es-hospedaje-jsx": () => import("./../../../src/pages/es/hospedaje.jsx" /* webpackChunkName: "component---src-pages-es-hospedaje-jsx" */),
  "component---src-pages-es-index-jsx": () => import("./../../../src/pages/es/index.jsx" /* webpackChunkName: "component---src-pages-es-index-jsx" */),
  "component---src-pages-es-marketing-digital-jsx": () => import("./../../../src/pages/es/marketing-digital.jsx" /* webpackChunkName: "component---src-pages-es-marketing-digital-jsx" */),
  "component---src-pages-hosting-jsx": () => import("./../../../src/pages/hosting.jsx" /* webpackChunkName: "component---src-pages-hosting-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-web-development-jsx": () => import("./../../../src/pages/web-development.jsx" /* webpackChunkName: "component---src-pages-web-development-jsx" */),
  "component---src-pages-work-jsx": () => import("./../../../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */),
  "component---src-templates-clients-jsx": () => import("./../../../src/templates/clients.jsx" /* webpackChunkName: "component---src-templates-clients-jsx" */)
}

